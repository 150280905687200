import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff, VpnKey as VpnKeyIcon } from '@material-ui/icons';

const PasswordInput = ({ error, name, value, handleChange, variant, label, placeholder, onBlur, InputProps, hideStartAdornment }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      error={!!error}
      name={name}
      value={value}
      onChange={handleChange}
      margin="normal"
      variant={variant}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      helperText={error}
      label={label}
      placeholder={placeholder}
      onBlur={onBlur}
      InputProps={{
        ...InputProps,
        startAdornment: !hideStartAdornment && (
          <InputAdornment position="start">
            <VpnKeyIcon style={{ marginRight: 5, color: 'grey' }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)} onMouseDown={e => e.preventDefault()}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const LoginInput = ({ error, name, value, handleChange, type, label, icon }) => (
  <TextField
    error={error !== ''}
    name={name}
    value={value}
    onChange={handleChange}
    margin="normal"
    variant="outlined"
    type={type}
    fullWidth
    helperText={error}
    placeholder={label}
    InputProps={{
      startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
    }}
  />
);

const TextInput = ({
  name,
  value,
  error,
  handleChange,
  handleBlur,
  label,
  disabled,
  type,
  hide,
  style = {},
  multiline,
  maxRows,
}) => {
  return hide ? null : (
    <TextField
      name={name}
      error={!!error}
      helperText={error}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      label={label}
      multiline={multiline}
      maxRows={maxRows}
      InputProps={{
        readOnly: disabled,
        disableUnderline: disabled,
        autoComplete: 'off',
      }}
      type={type}
      style={{ margin: '16px 0 8px', ...style }}
    />
  );
};

export { PasswordInput, LoginInput, TextInput };
