import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  titleStyle: { fontSize: 17, margin: '16px 0px 8px' },
  textStyle: { fontSize: 14, color: theme.palette.text.disabled },
}));

const Title = ({ text }) => {
  const classes = useStyles();
  return text ? <Typography className={classes.titleStyle}>{text}:</Typography> : null;
};

const TitleNoItems = ({ title, text, noItems }) => {
  const classes = useStyles();
  return (
    <div>
      <Title text={title} />
      {noItems && <Typography className={classes.textStyle}>{text}</Typography>}
    </div>
  );
};

export { Title, TitleNoItems };
