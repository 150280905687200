import moment from 'moment';
import { getDuration } from '../../../utils/utils';

const ERRORS = {
  project: 'The project is required',
  iteration: 'The iteration is required',
  story: 'The story is required',
  task: 'The task is required',
  invalidDate: 'Complete with a valid date',
  startBeforeEnd: 'The Start date must be before the End date',
  maxHours: "Efforts can't exceed 15 hours",
  responsibles: 'At least one responsible is required',
  participants: 'At least one participant is required'
};

const validate = values => {
  let errors = {};

  if (!values.project?.id) errors.project = ERRORS.project;

  if (!values.iteration?.id) errors.iteration = ERRORS.iteration;

  if (!values.task?.name) errors.task = ERRORS.task; //because its creatable

  if (!values.start || !moment(values.start).isValid()) errors.start = ERRORS.invalidDate;

  if (!values.end || !moment(values.end).isValid()) errors.end = ERRORS.invalidDate;

  if (values.start && values.end && values.start >= values.end) {
    errors.start = errors.end = ERRORS.startBeforeEnd;
  }

  if (getDuration(values.start, values.end) > 15) {
    errors.start = errors.end = ERRORS.maxHours;
  }

  if(values.responsibles.length === 0) {
    errors.responsibles = ERRORS.responsibles;
  }

  if(values.participants.length === 0) {
    errors.participants = ERRORS.participants;
  }

  return errors;
};

export default validate;
