import { colors, Fade, Tooltip, Typography, Zoom } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { pinTask, unpinTask, updateRanking } from '../../../redux/actions';
import { makeTaskPath, STATES } from '../../../utils/utils';
import { StyledListItem } from './StyledListItem';
import { useStyles } from './useStyles';

const ExpiredIcon = ({ className }) => (
  <Tooltip title="This iteration has expired">
    <AccessTimeIcon className={className} />
  </Tooltip>
);

const StarIcon = ({ pinned, setPinned }) => {
  const classes = useStyles();
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setPinned(!pinned);
  };

  const Icon = pinned ? Star : StarBorder;
  const tooltipTitle = pinned ? 'Remove from favorites' : 'Add to favorites';

  return (
    <Tooltip title={tooltipTitle}>
      <Icon className={classes.star} style={{ color: colors.amber[500] }} onClick={handleClick} />
    </Tooltip>
  );
};

export const TaskListItem = ({ task, handleClickTask }) => {
  const [hovered, setHovered] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handlePinned = pinned => {
    const action = pinned ? pinTask : unpinTask;
    dispatch(action(task.id));
    setHovered(false);
  };

  const updateRankingAndHandleClickTask = task => {
    dispatch(updateRanking(task.id));
    handleClickTask(task);
  };

  return (
    <StyledListItem
      button
      key={task.id}
      className={classes.listItem}
      onClick={() => {
        updateRankingAndHandleClickTask(task);
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        borderLeftColor: STATES[task.state].color,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Tooltip title={STATES[task.state].name}>
          <div
            style={{
              width: 10,
              marginLeft: -15,
              marginRight: 4,
            }}
          />
        </Tooltip>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div className={classes.headerAndFavContainer}>
            <Tooltip title={makeTaskPath(task)}>
              <Typography noWrap className={classes.listItemHeader}>
                {makeTaskPath(task)}
              </Typography>
            </Tooltip>
            <Zoom
              in={hovered || task.pinned}
              style={{
                transitionDelay: hovered ? '70ms' : '0ms',
              }}
            >
              <div
                style={{
                  display: hovered || task.pinned ? 'flex' : 'none',
                }}
              >
                <StarIcon pinned={task.pinned} setPinned={handlePinned} />
              </div>
            </Zoom>
          </div>
          <div className={classes.nameAndClockContainer}>
            <div className={classes.taskName}>
              <Tooltip title={task.name}>
                <Typography noWrap className={classes.listItemName}>
                  {task.name}
                </Typography>
              </Tooltip>
              <Fade in={hovered}>
                <div className={classes.timestampContainer}>
                  <Typography noWrap className={classes.timestamp}>
                    {task.lastUsed ? `Used ${moment(task.lastUsed).locale('en').fromNow()}` : 'Never used'} - (
                    {STATES[task.state].name})
                  </Typography>
                </div>
              </Fade>
            </div>

            <div style={{ display: task.expired ? 'flex' : 'none' }}>
              <ExpiredIcon className={classes.clock} />
            </div>
          </div>
        </div>
      </div>
    </StyledListItem>
  );
};
