import { CircularProgress, Grid, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { SuccessToast } from '../../components/common';
import logo from '../../assets/logo.png';
import ClassicLoginForm from './ClassicLoginForm';
import OauthLoginForm from './OauthLoginForm';
import styles from './styles';

const LoginComponent = ({
  classes,
  data,
  handleChange,
  handleSubmit,
  successMessage,
  onCloseToast,
}) => {
  const [oauthSignIn, setOauthSignIn] = useState(true);

  return (
    <div className={classes.background}>
      {data.token ? (
        <div className={classes.loadingToken}>
          <CircularProgress size={60} />
          <p style={{ fontSize: 18 }}>Logging in...</p>
        </div>
      ) : (
        <>
          {data.loading && (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          )}
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.gridLogin}
          >
            <form className={classes.formLogin} onSubmit={handleSubmit}>
              <img
                src={logo}
                width={200}
                alt="Logo"
                style={{ margin: '0 auto', paddingBottom: 15 }}
              />
              {!oauthSignIn ? (
                <ClassicLoginForm
                  data={data}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  classes={classes}
                  handleClickSwithSignInType={() => setOauthSignIn(true)}
                />
              ) : (
                <OauthLoginForm
                  handleClickSwithSignInType={() => setOauthSignIn(false)}
                />
              )}
            </form>
          </Grid>
          <SuccessToast
            open={successMessage !== ''}
            onClose={onCloseToast('successMessage')}
            text={successMessage}
          />
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(LoginComponent);
