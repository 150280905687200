import React from 'react';
import Alert from '@material-ui/lab/Alert/Alert';
import Snackbar from '@material-ui/core/Snackbar/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  icon: {
    color: '#FFFFFF !important',
  },
});

const Toast = withStyles(styles)(props => {
  const { open, duration = 6000, onClose, anchor = { vertical: 'bottom', horizontal: 'right' }, text, type, style, classes } = props;

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={onClose} anchorOrigin={anchor}>
      <Alert onClose={onClose} severity={type} style={style} classes={{ icon: classes.icon }}>
        {text}
      </Alert>
    </Snackbar>
  );
});

const SuccessToast = props => <Toast type="success" style={{ backgroundColor: '#4caf50', color: '#FFFFFF' }} {...props} />;

const ErrorToast = props => <Toast type="error" style={{ backgroundColor: '#f44336', color: '#FFFFFF' }} {...props} />;

const WarningToast = props => <Toast type="warning" style={{ backgroundColor: '#ff9800', color: '#FFFFFF' }} {...props} />;

export { SuccessToast, ErrorToast, WarningToast };
