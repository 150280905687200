import { Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import google from '../../assets/google.svg';
import config from '../../utils/config';

export default function OauthLoginForm({ handleClickSwithSignInType }) {

  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const level = params.get('level');
    if(level !== 'error'){
      return;
    }
    const error = params.get('message');
    const uuid = params.get('uuid');
    setError(`${error}(${uuid})`);
  }, [location]);

  return (
    <>
      <div>
        <Button
          variant="contained"
          fullWidth
          style={{
            height: '40px',
            backgroundColor: '#FFF',
            borderRadius: '0',
            width: 'auto',
          }}
          onClick={() => {
            window.location.replace(`${config.backEndUrl}auth/login/oauth2/google`);
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src={google}
              alt="Google"
              style={{
                width: 18,
                height: 18,
                marginLeft: '8px',
              }}
            />
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: '500',
                marginLeft: '24px',
                marginRight: '32px',
              }}
            >
              Sign in with Google
            </Typography>
          </div>
        </Button>
      </div>
      <Typography
        onClick={handleClickSwithSignInType}
        style={{
          cursor: 'pointer',
          marginTop: '12px',
          fontSize: '14px',
        }}
      >
        Sign in with username and password
      </Typography>
      <div
        style={{
          display: error? 'flex': 'none',
          backgroundColor: '#FF4646',
          borderRadius: '4px',
          padding: '5px',
          marginTop: '14px',
        }}>
        <Typography
          style={{
            color: '#FFF',
            fontSize: '12px',
          }}
        >
          {error}
        </Typography>
      </div>
    </>
  );
}
