import moment from 'moment';
import * as actions from '../actionTypes';
import generateReducer from './generateReducer';
import { timersMock } from '../../utils/mocks';

const initialState = {
  // timers: [],
  timers: [],
};

const handler = {
  [actions.UPDATE_HEARTBEAT]: state => {
    return {
      ...state,
      timers: state.timers.map(t => (t.end == null ? { ...t, lastUpdate: moment() } : t)),
    };
  },

  [actions.START_TIMER]: (state, action) => ({
    ...state,
    timers: [
      ...state.timers,
      {
        name: action.name,
        id: state.timers.length,
        start: moment(),
        end: null,
        lastUpdate: moment(),
      },
    ],
  }),
  [actions.END_TIMER]: (state, action) => {
    return {
      ...state,
      timers: state.timers.map(t => (t.id === action.id ? { ...t, end: moment() } : t)),
    };
  },
  [actions.DELETE_TIMER]: (state, action) => {
    return {
      ...state,
      timers: state.timers.filter(t => t.id !== action.id),
    };
  },
  [actions.LINK_WITH_TASK]: (state, action) => {
    return {
      ...state,
      timers: state.timers.map(t =>
        t.id === action.linked.timerId ? { ...t, task: action.linked.task } : t
      ),
    };
  },
  [actions.RENAME_TIMER]: (state, action) => {
    return {
      ...state,
      timers: state.timers.map(t =>
        t.id === action.timer.id ? { ...t, name: action.timer.name } : t
      ),
    };
  },
};

export default generateReducer(handler, initialState);
