import { IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { CallReceived, Stop } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { endTimer, renameTimer, updateHeartbeat } from '../../redux/actions';
import config from '../../utils/config';
import { getUserId } from '../../utils/utils';

const getDiff = (start, end) => moment.duration((end || moment()).diff(start, 'ms'));
const formatTimer = duration => moment.utc(duration.asMilliseconds()).format('H:mm:ss');

const Timer = ({
  info,
  handleAction,
  addEvent,
  actionIcon = <CallReceived />,
  actionTooltip,
  onStop,
}) => {
  const { id: timerId, name: timerName, start, end, lastUpdate } = info;

  const [timerDuration, setTimerDuration] = useState(getDiff(start, end));
  const dispatch = useDispatch();
  const [shouldShowUnderline, setShouldShowUnderline] = useState(false);
  const handleEditName = e => {
    const newName = e.target.value;
    dispatch(
      renameTimer({
        id: timerId,
        name: newName,
      })
    );
  };

  useEffect(() => {
    const updateTime = () => setTimerDuration(getDiff(start, end));

    let interval;

    // Updates the clock in the UI and the lastUpdate in localStorage

    if (!end && !interval) {
      interval = setInterval(() => {
        if (moment().diff(lastUpdate, 'seconds') >= config.heartBeatRefreshIntervalSeconds) {
          dispatch(updateHeartbeat());
        }

        updateTime();
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [start, end, lastUpdate, dispatch]);

  const stopTimer = () => {
    dispatch(endTimer(timerId));
    const task = info.task;
    if (!task) {
      onStop(false);
      return;
    }

    const { id, name, story, iteration, project } = task;

    addEvent({
      start: start.toDate(),
      end: moment().toDate(),
      name,
      project,
      iteration,
      story,
      id,
      participants: [{fullName: "you", id: getUserId()}]
    })
    onStop(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Tooltip title={timerName}>
        <TextField
          onFocus={e => {
            setShouldShowUnderline(true);
          }}
          onBlur={e => {
            setShouldShowUnderline(false);
          }}
          style={{
            width: '-webkit-fill-available',
          }}
          onChange={handleEditName}
          noWrap
          value={timerName}
          color="#000"
          InputProps={{
            disableUnderline: !shouldShowUnderline,
          }}
          inputProps={{
            style: {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            },
          }}
        ></TextField>
      </Tooltip>
      <Typography style={{ marginRight: 10, marginLeft: 10 }}>
        {formatTimer(timerDuration)}
      </Typography>
      <Tooltip title={'Stop'}>
        <div>
          <IconButton size={24} onClick={stopTimer} disabled={!!end}>
            <Stop />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title={actionTooltip}>
        <IconButton
          size={24}
          onClick={() => {
            handleAction({ ...info });
          }}
        >
          {actionIcon}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Timer;
