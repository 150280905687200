const ERRORS = {
  project: 'The project is required',
  iteration: 'The iteration is required',
  story: 'The story is required',
  task: 'The task is required',
};

const validate = values => {
  let errors = {};

  if (!values.project?.id) errors.project = ERRORS.project;

  if (!values.iteration?.id) errors.iteration = ERRORS.iteration;

  if (!values.story?.id) errors.story = ERRORS.story;

  if (!values.task?.name) errors.task = ERRORS.task; //because its creatable

  return errors;
};

export default validate;
