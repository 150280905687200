import React, { useState } from 'react';
import { TextInput } from './TextInput';
import { Add } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { startTimer } from '../../redux/actions';

const AddTimer = () => {
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const timers = useSelector(state => state.timers.timers);
  const dispatch = useDispatch();

  const onAddTimer = () => {
    if (timers.some(t => !t.end)) {
      setError('Stop the ongoing timer to create a new one');
    } else if (text && timers.some(t => t.name === text)) {
      setError('A timer with this name already exists');
    } else {
      const newText = text.trim() || `Stopwatch ${timers.length}`;
      dispatch(startTimer(newText));

      setText('');
      setError('');
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextInput
        label="Create stopwatch"
        style={{
          marginLeft: 10,
          width: '-webkit-fill-available',
          paddingBottom: !error && 6,
        }}
        error={error}
        value={text}
        handleChange={event => {
          setText(event.target.value);
          setError('');
        }}
      />
      <Tooltip title="Create">
        <IconButton size={'medium'} style={{ marginLeft: 5, marginRight: 5 }} onClick={onAddTimer}>
          <Add />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default AddTimer;
