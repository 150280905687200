import React, { useEffect, useState, memo } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { isEqual } from 'lodash';
import { textFieldStyle, autocompleteStyle } from './styles';

function ArrayAutocomplete({
  label,
  value,
  field,
  nameField = field,
  options,
  error,
  handleChange,
  disabled,
  showInput = false,
  hide,
  useFieldInTextField,
}) {
  const [filtered, setFiltered] = useState([]);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value) {
      setFiltered([value]);
      setCurrentValue(value);
    } else {
      setCurrentValue('');
      setFiltered(options.slice(0, 5));
    }
  }, [value, options]);

  const onInputChange = (event, text) => {
    const regex = RegExp('^' + text, 'i');
    const filteredOptions = options.filter(option => regex.test(option[field])).slice(0, 5);
    filteredOptions.sort((a, b) => (a[field] > b[field] ? 1 : -1));
    setFiltered(filteredOptions);
  };

  const handleSelect = (event, newValue) => {
    handleChange(newValue);
    setCurrentValue(newValue);
  };

  return hide ? null : showInput ? (
    <TextField
      value={(useFieldInTextField ? value[field] : value) || ''}
      label={label}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
      }}
      style={textFieldStyle}
    />
  ) : (
    <Autocomplete
      disabled={disabled}
      value={currentValue || null}
      options={filtered}
      getOptionLabel={option => option[nameField] || ''}
      onInputChange={onInputChange}
      onChange={(event, newValue) => handleSelect(event, newValue)}
      autoHighlight
      noOptionsText="No options found"
      openText="Open"
      clearText="Delete"
      closeText="Close"
      getOptionSelected={(option, value) => isEqual(option, value)}
      style={autocompleteStyle}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          error={error !== undefined}
          helperText={error}
        />
      )}
    />
  );
}

export default memo(ArrayAutocomplete);
