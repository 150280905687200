import moment from 'moment';

export const timersMock = [
  {
    id: 0,
    name: 'Cronometro 1',
    start: moment().subtract(120, 'm'),
    end: moment().subtract(60, 'm'),
  },
  {
    id: 1,
    name: 'Cronometro 2 2 2 2 2 22 2',
    start: moment().subtract(45, 'm'),
    end: moment().subtract(30, 'm'),
  },
  { id: 2, name: 'Cronometro 3', start: moment().subtract(25, 'm'), end: null },
];
