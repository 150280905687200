import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import 'moment/locale/es-mx';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addTask, linkWithTask } from '../../../../redux/actions';
import { createTask, getTasksPath } from '../../../../utils/tasks';
import { fetchTaskInfo, getUserId, STATES } from '../../../../utils/utils';
import ApiAutocomplete from '../../Autocomplete/ApiAutocomplete';
import ArrayAutocomplete from '../../Autocomplete/ArrayAutocomplete';
import AutocompleteChipSection from '../../Autocomplete/AutocompleteChipSection';
import { PrimaryButton, SecondaryButton } from '../../Button';
import useFormValidation from '../../hooks/useFormValidation';
import { TextInput } from '../../TextInput';
import validate from './validate';

const INITIAL_STATE = {
  project: '',
  iteration: '',
  story: '',
  task: '',
  state: '',
  comments: '',
  responsibles: [
    {
      id: getUserId(),
      fullName: 'You',
    }
  ],
  participants: [
    {
      id: getUserId(),
      fullName: 'You',
    }
  ]
};

const LABELS = {
  project: 'Project',
  iteration: 'Iteration',
  story: 'Story',
  task: 'Task',
  state: 'State',
  comments: 'Comments',

};

const useStyles = makeStyles(() => ({
  dialogTitle: { padding: '24px 16px 0px', textAlign: 'center' },
  dialogContent: { display: 'flex', flexDirection: 'column', paddingTop: 0 },
  warningText: { marginBottom: 0, color: '#ff9800', fontSize: 16 },
  endContainer: { display: 'flex' },
  dialogActions: { display: 'flex', justifyContent: 'space-between', padding: '16px 24px 24px' },
}));

const CreateTaskFromTimer = ({ open, handleClose, selectedTimer, addEvent, history }) => {
  const classes = useStyles();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    resetValues,
  } = useFormValidation(INITIAL_STATE, v => validate(v));

  const handleChangeAutocomplete = useCallback(
    (name, value) => handleChange({ target: { name, value } }),
    [handleChange]
  );
  const handleChangeProject = useCallback(
    value => handleChangeAutocomplete('project', value),
    [handleChangeAutocomplete]
  );
  const handleChangeIteration = useCallback(
    value => handleChangeAutocomplete('iteration', value),
    [handleChangeAutocomplete]
  );
  const handleChangeStory = useCallback(
    value => handleChangeAutocomplete('story', value),
    [handleChangeAutocomplete]
  );
  const handleChangeTask = useCallback(
    value => handleChangeAutocomplete('task', value),
    [handleChangeAutocomplete]
  );
  const handleChangeState = useCallback(
    value => handleChangeAutocomplete('state', value),
    [handleChangeAutocomplete]
  );

  const dispatch = useDispatch();

  useEffect(() => {

    resetValues(INITIAL_STATE);

  }, [resetValues, selectedTimer]);

  const handleClickSave = async e => {
    if(!handleSubmit(e)) return;
    
    const { project, iteration, story, task, state, comments } = values;
    const addEventForNewTask = (taskId) => addEvent({
      start: selectedTimer.start.toDate(),
      end: selectedTimer.end.toDate(),
      name: task.name,
      project,
      iteration,
      story,
      id: taskId,
      comments,
      participants: values.participants
    })
    const id = task.id;
    if (!id) {
      const taskId = await createTask(project, iteration, story, task, state)
      const newTask = {
        id: taskId,
        name: task.name,
        project,
        iteration,
        story,
      }
      dispatch(addTask(newTask));
      dispatch(linkWithTask({
        timerId: selectedTimer.id,
        task: newTask,
      }));
      if(!selectedTimer.end) {
        handleClose();
        return;
      };
      
      addEventForNewTask(taskId);
    } else {
      const newTask = {
        id: task.id,
        name: task.name,
        project,
        iteration,
        story,
      }

      dispatch(addTask(newTask));
      dispatch(linkWithTask({
        timerId: selectedTimer.id,
        task: newTask,
      }));
      if(!selectedTimer.end) {
        handleClose();
        return;
      }
      addEvent({
        start: selectedTimer.start.toDate(),
        end: selectedTimer.end.toDate(),
        name: task.name,
        project: project,
        iteration: iteration,
        story: story,
        id: id,
        comments,
        participants: values.participants
      });
    }
    handleClose();
  };

  useEffect(() => {
    if(!values.task) return;
    fetchTaskInfo(0, values.project.id, values.iteration.id, values.story.id, values.task.id).then(({state}) => handleChangeState(state)).catch(handleChangeState(STATES[0]));
  }, [handleChangeState, values.iteration.id, values.project.id, values.story.id, values.task]);

  // Este código se repite con Manual Load Dialog. Abstraer luego del merge
  const customHandleAdd = useCallback(
    (field, value) => ({ id, fullName}) =>
      handleChange({
        target: {
          name: field,
          value: [...value, {id, fullName: id === getUserId() ? 'You' : fullName}],
        },
      }),
    [handleChange]
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>Create Task</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ApiAutocomplete
          path="projects"
          field="name"
          value={values.project}
          label={LABELS.project}
          handleChange={handleChangeProject}
          error={errors.project}
        />
        <ApiAutocomplete
          path= {`clients/0/projects/${values.project.id}/iterations`}
          field="name"
          value={values.iteration}
          label={LABELS.iteration}
          handleChange={handleChangeIteration}
          error={errors.iteration}
          disabled={!values.project}
          dependsOn={values.project}
        />
        <ApiAutocomplete
          path={`clients/0/projects/${values.project.id}/iterations/${values.iteration.id}/stories`}
          field="name"
          value={values.story}
          label={LABELS.story}
          handleChange={handleChangeStory}
          error={errors.story}
          disabled={!values.iteration}
          dependsOn={values.iteration}
        />
        <ApiAutocomplete
          path={getTasksPath(values)}
          field="name"
          value={values.task}
          label={LABELS.task}
          handleChange={handleChangeTask}
          error={errors.task}
          freeSolo
          createText="Create the task"
          dependsOn={values.iteration}
          disabled={!values.iteration}
        />
        <ArrayAutocomplete
          options={STATES}
          error={errors.state}
          label={LABELS.state}
          value={values.state}
          handleChange={handleChangeState}
          field="name"
          useFieldInTextField
          disabled={!values.task}
        />
        <AutocompleteChipSection
          editMode
          path={'users'}
          apiField={'fullName'}
          idField={'id'}
          field="responsibles"
          value={values.task? values.responsibles ?? [] : []}
          handleChange={handleChange}
          handleBlur={handleBlur}
          helpText={'Add more people to this task so it will appear in their Quick Load Dialog'}
          label={'Add more responsibles to this task'}
          dependsOn={values.task}
          disabled={!values.task}
          customHandleAdd={customHandleAdd("responsibles", values.responsibles)}
          error={errors.responsibles}
        />
        <TextInput
          multiline
          maxRows={3}
          name="comments"
          value={values.comments}
          error={errors.comments}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label="Comments"
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        <PrimaryButton
          onClick={ handleClickSave}
        >
          Save
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTaskFromTimer;
