import { List } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { StyledListItem } from "./StyledListItem";
import { useStyles } from "./useStyles";

const TaskSkeleton = ({className}) => (
  <StyledListItem className={className}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
      }}
    >
      <Skeleton variant="text" height={20} width={60} style={{ marginRight: 8 }} />
      <Skeleton variant="text" height={20} width={50} />
    </div>
    <Skeleton variant="text" width={'100%'} height={30} />
  </StyledListItem>
);

export const TaskListSkeleton = () => {
  const classes = useStyles();
  return <List className={classes.list}>{Array(6).fill(<TaskSkeleton className={classes.listItem}/>)}</List>;
};