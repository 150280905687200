import { colors, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  list: { padding: 0, marginTop: 8, width: '100%', overflow: 'auto', maxHeight:'inherit'/*maxHeight: '49vh', minHeight: '49vh'*/ },
  listItem: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderLeftStyle: 'solid', borderLeftWidth: 5, borderLeftColor: "#FFF" },
  listItemHeader: { fontSize: 12, maxWidth: '100%', color: '#737373' },
  listItemName: { maxWidth: '100%', fontSize: 15 },
  selectTaskHeader: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' },
  cantFindListItem: { marginBottom: 8, display: 'flex', justifyContent: 'center' },
  star: { width: 16, height: 16 },
  clock: { color: '#ff0000', width: 16, height: 16 },
  timestamp: { fontSize: 10, color: colors.grey[600],},
  headerAndFavContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  nameAndClockContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
  },
  taskName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    width: '100%',
    gap: 8,
  },
  timestampContainer: {
    display: 'flex',
    flexDirection: 'row',
  }
}));
