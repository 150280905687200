import moment from 'moment';
import * as actions from '../actionTypes';
import generateReducer from './generateReducer';

const initialState = {
  selectedCriteria: 0,
  lastUpdated: null,
  tasks: {},
  pinned: [],
};

const handler = {
  [actions.UPDATE_RANKING]: (state, action) => {
    const { id } = action;
    const tasks = { ...state.tasks };
    const lastUsed = moment().format();
    const task = tasks[id];
    return {
      ...state,
      tasks: {
        ...tasks,
        [id]: { lastUsed, counter: task?.counter + 1 || 1}
      }
    };
  },
  [actions.SET_CRITERIA]: (state, action) => ({
    ...state,
    selectedCriteria: action.selectedCriteria
  }),
  [actions.CLEAN_RANKING]: (state, action) => ({
    ...state,
    tasks: action.cleaned,
    lastUpdated: moment()
  }),
  [actions.PIN_TASK]: (state, {id}) => ({
    ...state,
    pinned: [...state.pinned, id]
  }),
  [actions.UNPIN_TASK]: (state, {id}) => ({
    ...state,
    pinned: state.pinned.filter(pinnedId => pinnedId !== id)
  }),
};

export default generateReducer(handler, initialState);
